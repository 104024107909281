import { render, staticRenderFns } from "./FlickityCarousel.vue?vue&type=template&id=2698e286&scoped=true"
import script from "./FlickityCarousel.vue?vue&type=script&lang=js"
export * from "./FlickityCarousel.vue?vue&type=script&lang=js"
import style0 from "./FlickityCarousel.vue?vue&type=style&index=0&id=2698e286&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2698e286",
  null
  
)

export default component.exports