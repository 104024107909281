<template>
  <StatsCarousel></StatsCarousel>
</template>

<script>
import StatsCarousel from '@/components/StatsCarousel.vue'

export default {
  name: 'StatsView',
  components: {
    StatsCarousel,
  }
}
</script>

<style>

</style>