<template>
  <div class="container shadow">
    <h1>Sorry, I couldn't find that page</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .container {
        width: 100%;
        height: calc(100vh - 60px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>